<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten"
                     style="min-height: calc(100% - 48px);background-color:#fff;min-width:calc(100% - 32px);width:max-content">
                  
                    <a-card :bordered="false"
                            style="min-width:min-content; min-height:100%;"
                            :tab-list="tabListNoTitle"
                            :active-tab-key="noTitleKey"
                            @tabChange="key => onTabChange(key, 'noTitleKey')">
                    </a-card>
                  
                    <TimeSetting v-if="noTitleKey==1"></TimeSetting>

                </div>
         
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
    import TimeSetting from './components/TimeSetting'
    export default {
        name: "Operations_Setting",
        data() {
            return {
                tabListNoTitle: [
                    {
                        key: "1",
                        tab: "客户保护期"
                    }],
                noTitleKey: "1"
            };
        },
        methods: {
            onTabChange(key, type) {
                this[type] = key;
                this.noTitleKey = key;
            },
        },
        computed: {},
        components: {
            TimeSetting
        }
    };
</script>
