<template>
    <a-form  style="padding:10px 00px;height:auto;width:1400px;">
        <a-form-item label="客户保护期"
                     style="margin-bottom:10px;margin-top:20px;"
                     :label-col="formItemLayout.labelCol"
                     :wrapper-col="formItemLayout.wrapperCol">
            <a-input placeholder="请输入整数或为空" :value="EffectiveTime" @change="Timechange"></a-input>
           
        <a-form-item label=""> <div class="justify">默认为空，永久保护；0为不保护；<p> 在保护期内，无论其他人如何分享引流，该客户一直隶属于来源团队成员。超过保护期如有其他团队分享将划归到其他团队名下。</p></div>
        </a-form-item></a-form-item>
        <a-form-item>
            <a-button style="margin-left:14rem;"
                      type="primary" size="large"
                      @click="save()"
                      :loading="loading">保存</a-button>
        </a-form-item>
    </a-form>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "TimeSetting",
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 5 }
                    }
                },
                EffectiveTime: null,
                loading: false
            };
        },
        methods: {
            Timechange(e) {
                this.EffectiveTime = e.target.value;
            },
            getEffectiveTime: function () {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/GetAgentEffectiveTime",
                    data: {},
                    OnSuccess: function (res) {
                        self.EffectiveTime = res.data
                    }
                };
                http.Post(op);
            },
            save() {
                var self = this;
                if (util.isBlank(self.EffectiveTime)) {
                    self.loading = true;
                    self.post(self.EffectiveTime)
                    self.loading = false;
                } else {
                    var tempData = parseInt(self.EffectiveTime);
                    if (tempData < 0) {
                        self.$message.error("有效时间必须为正整数或为空");
                        return;
                    }
                    if (tempData > 2147483647) {
                        self.$message.error("有效时间超过最大长度2147483647");
                        return;
                    }
                    if (isNaN(tempData)) {
                        self.$message.error("有效时间必须为正整数或为空");
                        return;
                    } else {
                        self.loading = true;
                        self.post(tempData)
                        self.loading = false;
                    }
                }

            },
            post(time) {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/UpdateEffectiveTime",
                    data: {
                        Time: time
                    },
                    OnSuccess: function () {
                        self.$message.success("设置保存成功");
                        self.getEffectiveTime();
                    }
                };
                http.Post(op);
            }
        },
        mounted() {
            this.getEffectiveTime();
        },
        computed: {},
        components: {}
    };
</script>
<style>

    .justify {
        margin-top: 10px;
        text-align: justify;
        width: 100%;
        font-size: 15px;
    }
</style>
